html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
html {
    font-size: 50px;
}
@media (max-width: 1200px) {
    html {
        font-size: 45px;
    }
}
@media (max-width: 860px) {
    html {
        font-size: 42px;
    }
}
@media (max-width: 500px) {
    html {
        font-size: 38px;
    }
}
body {
    background: #fff;
    font-family: "Open Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.3;
}
* {
    box-sizing: border-box;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
}
::-webkit-scrollbar {
    width: 9px;
    background-color: #f5f5f5;
}
::-webkit-scrollbar-thumb {
    background-color: rgb(187, 8, 8);
    background-image: -webkit-linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.2) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0.2) 75%,
        transparent 75%,
        transparent
    );
}
a {
    color: red;
    text-decoration: none;
}
.bold,
.strong,
b,
strong {
    font-weight: bold;
}
img {
    max-width: 100%;
    height: auto;
}
.App {
    margin: 0 auto;
    max-width: 540px;
    position: relative;
    min-height: 100vh;
    box-shadow: 0 0 0.13333rem 0 rgba(125, 124, 124, 0.3);
}
input:focus,
select:focus,
select:active {
    outline: 0.01333rem solid red;
    -webkit-user-select: text;
    user-select: text;
}
.title {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    margin-bottom: 0.32rem;
    color: #333;
    font-size: 0.4rem;
    font-weight: 600;
}
.title:before {
    content: "";
    display: block;
    width: 0.08rem;
    height: 0.4rem;
    background: red;
    margin-right: 0.13333rem;
}
.title-h1 {
    text-align: center;
    color: #fff;
    font-size: 0.7rem;
    font-weight: bold;
    position: relative;
    z-index: 1;
    margin: 0.6rem 0;
}
.login {
    padding: 0 0.32rem;
    min-height: 100vh;
    background: linear-gradient(hsla(0, 0%, 100%, 0), #b16a6a), url(./img/login-bg.png);
    display: flex;
}
.form-lg input {
    width: 90%;
    height: 1.17333rem;
    padding: 0.36rem 0.34667rem;
    margin: 0 0 0.2rem;
    font-size: 0.37333rem;
    border: none;
    border-radius: 0.26667rem;
    box-shadow: 0 0.05333rem 0.21333rem #d0d0ed5c;
}
.form-lg select {
    width: 90%;
    height: 1.17333rem;
    padding: 0.36rem 0.34667rem;
    margin: 0 0 0.2rem;
    font-size: 0.37333rem;
    border: none;
    border-radius: 0.26667rem;
    box-shadow: 0 0.05333rem 0.21333rem #d0d0ed5c;
}
.form-lg label {
    margin: 0.2rem auto 0.1rem;
    text-align: left;
    max-width: 90%;
    display: block;
}
.login h1 {
    margin: 0 0 0.2rem;
    text-transform: uppercase;
    color: #fff;
    font-size: 0.5rem;
    font-weight: bold;
}
.form-lg {
    width: 100%;
}
.form-lg .inputs {
    padding-bottom: 0.2rem;
}
.form-lg p:not(:empty) {
    color: #333;
    font-size: 0.4rem;
    margin-bottom: 0.5rem;
}
.form-lg button {
    height: 1.06667rem;
    color: #fff;
    font-size: 0.48rem;
    font-weight: 700;
    letter-spacing: 0.05333rem;
    text-shadow: 0 0.05333rem 0.02667rem #ff0000;
    border-radius: 1.06667rem;
    border: none;
    background: linear-gradient(180deg, #ee8d8d 0%, #ff0000 100%);
    box-shadow: 0 0.05333rem #e53636;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: 0.5rem auto;
}
.login form .input {
    position: relative;
}
.login form svg {
    font-size: 24px;
    color: #666;
    right: 35px;
    top: 32px;
    position: absolute;
}
.form-lg input[type="radio"] {
    width: 15px;
    height: 15px;
    outline: none !important;
    margin: 3px 3px 0;
}
input[type="radio"]:focus,
input[type="radio"]:active {
    outline: none !important;
    border: 0 !important;
}
.main {
    background: #fff7f7;
    padding: 0 0.32rem 2rem;
    min-height: 100vh;
}
.main .header {
    position: relative;
    margin-left: -0.32rem;
    margin-right: -0.32rem;
    padding: 0 0.32rem;
}
.main .header:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(0deg, #ee8d8d 0%, #ff0000 100%);
    height: 4rem;
    width: 100%;
    z-index: 0;
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
}
.main .header .header-top {
    height: 1.22667rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
}
.main .header .header-right {
    color: #fff;
    font-size: 0.333rem;
}
.main .header .header-right svg {
    height: 0.333rem;
    width: 0.333rem;
}
.main .header .logo img {
    max-height: 0.9rem;
    width: auto;
}
.main .swiper {
    border-radius: 8px;
}
.main .list-game {
    display: grid;
    grid-gap: 0.3rem;
    margin: 0.3rem 0;
}
.main .box-game a {
    display: block;
    position: relative;
    width: 100%;
    height: 2.26667rem;
    text-align: end;
    border-radius: 0.26667rem;
    background: -webkit-linear-gradient(325.68deg, #df2a2a 12.08%, #ee8d8d 85.02%);
    background: linear-gradient(124.32deg, #df2a2a 12.08%, #ee8d8d 85.02%);
}
.main .box-game img {
    height: 100%;
    width: auto;
}
.main .box-game h3 {
    position: absolute;
    top: 0.26667rem;
    left: 0.4rem;
    color: #fff;
    white-space: break-spaces;
    font-weight: 700;
    font-size: 0.46667rem;
}
.main .box-game .box-game-text {
    color: #f4f5f8;
    font-weight: 400;
    font-size: 0.29333rem;
    position: absolute;
    bottom: 0.26667rem;
    left: 0.4rem;
    white-space: pre-wrap;
    text-align: left;
    line-height: 0.46667rem;
}
.main .box-game.op a {
    text-align: start;
    background: linear-gradient(-124.32deg, #df2a2a 12.08%, #ee8d8d 85.02%);
}
.main .box-game.op h3 {
    left: auto;
    right: 0.6em;
}
.main .box-game.op .box-game-text {
    left: auto;
    right: 1em;
    text-align: right;
}
.tab-navigation {
    line-height: 0.96rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    flex-flow: row wrap;
    margin: 0.8rem auto 0;
}
.tab-navigation > li {
    flex: 1;
    height: 100%;
    background: #e8e7e8;
    border-radius: 0.21333rem;
    font-size: 0.37333rem;
    color: #333;
    margin: 0 0.32rem;
    text-align: center;
    overflow: hidden;
    cursor: pointer;
}
.tab-navigation > li.active {
    background: -webkit-linear-gradient(324.57deg, #4ca6ff 12.38%, #7ddced 87.13%);
    background: red;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.25);
    font-weight: 600;
    color: #fff;
}
.tab-content {
    font-size: 0.333rem;
    margin: 0.32rem;
    background: #fff;
    border-radius: 0.13333rem;
    overflow: hidden;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
}
table {
    width: 100%;
    border-collapse: collapse;
}
table th,
table td {
    padding: 0.2rem;
}
table thead {
    background: linear-gradient(125.43deg, #ff4c4c 12.38%, #f56464 87.13%);
    color: #fff;
    font-size: 0.4rem;
    font-weight: 500;
}
table tbody td {
    text-align: left;
    vertical-align: middle;
}
table tbody td:last-child {
    text-align: right;
}
table tr {
    border-bottom: 1px solid #ebebeb;
}
table thead th {
    background-color: #f5f5f5;
    font-weight: bold;
}
table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}
table tbody tr:hover {
    background-color: #ebebeb;
}
.award_tb {
    max-height: 10.3rem;
    overflow: auto;
}
.redball {
    background: red;
    color: #fff;
    margin: 0 0.033rem;
    border-radius: 100%;
    width: 0.5rem;
    height: 0.5rem;
    line-height: 0.47rem;
    font-size: 0.33rem;
    text-align: center;
}
.ball_xs {
    display: flex;
}
.ball_xs .ball {
    background: red;
    color: #fff;
    margin: 0 0.04rem;
    border-radius: 100%;
    width: 0.7rem;
    height: 0.7rem;
    line-height: 0.65rem;
    font-size: 0.53rem;
    text-align: center;
}
.record_bet {
    min-height: 3.27333rem;
    background: #ffffff;
    border-radius: 0.53333rem;
    font-size: 0.444rem;
    color: #8b8b8b;
    position: relative;
    z-index: 1;
}
.colum-resultxs {
    display: flex;
    flex-flow: row wrap;
    padding: 0.3rem 0 0;
}
.colum-resultxs > .col-50 {
    width: 50%;
    padding: 0.1rem 0.3rem 0.1rem;
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    justify-content: center;
}
.colum-resultxs > .col-50 .info_bet {
    width: 100%;
    white-space: nowrap;
}
.colum-resultxs > .col-100 {
    width: 100%;
    padding: 0.3rem 0.5rem 0.5rem;
}
.colum-resultxs > div:first-child {
    border-right: 1px solid #eee;
}
.xs_before {
    margin: 0.2rem 0 0.3rem;
    color: #000;
    font-weight: 600;
}
.record_bet .count {
    height: 0.8rem;
    line-height: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.3rem 0 0;
}
.record_bet .count > div {
    background: #efeff4;
    border-radius: 0.05333rem;
    color: red;
    font-weight: 700;
    font-size: 0.48rem;
    text-align: center;
    padding: 0 0.13333rem;
    border: 0.00667rem solid #fff;
}
.record_bet .count2 {
    height: 1.2rem;
    line-height: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.3rem 0 0;
}
.record_bet .count2 > div {
    background: #efeff4;
    border-radius: 0.05333rem;
    color: red;
    font-weight: 700;
    font-size: 0.6rem;
    text-align: center;
    padding: 0 0.23333rem;
    margin: 0 0.05rem;
    border: 0.00667rem solid #fff;
}
.record_bet .count > .notime,
.record_bet .count2 > .notime {
    background: transparent;
}
.btn-mini {
    padding: 0 0.15rem;
    height: 0.61333rem;
    font-size: 0.25333rem;
    text-align: center;
    border: 0.01333rem solid red;
    border-radius: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
    background: #fff;
    margin: auto;
}
.btn-medium {
    padding: 0.1rem 0.3rem;
    height: 0.81333rem;
    font-size: 0.35333rem;
    text-align: center;
    border: 0.01333rem solid red;
    border-radius: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
    background: #fff;
    margin: auto;
}
.box-quay {
    height: 3.09333rem;
    margin-top: 0.48rem;
    background: #00b977;
    border-radius: 0.18667rem;
    position: relative;
    padding: 0.26667rem;
}
.box-quay:after,
.box-quay:before {
    content: "";
    display: block;
    width: 0.13333rem;
    height: 0.69333rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 0;
}
.box-quay:before {
    background: #008b59;
    border-radius: 0.13333rem 0 0 0.13333rem;
    left: -0.13333rem;
}
.box-quay:after {
    background: #008b59;
    border-radius: 0 0.13333rem 0.13333rem 0;
    right: -0.13333rem;
}
.box-quay .box {
    background: #003c26;
    border-radius: 0.13333rem;
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.13333rem;
}
.box-quay .box:after,
.box-quay .box:before {
    position: absolute;
    width: 0;
    height: 0;
    border-top: 0.18667rem solid transparent;
    border-bottom: 0.18667rem solid transparent;
    content: "";
    z-index: 3;
}
.box-quay .box:before {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-right: 0.53333rem solid transparent;
    border-left: 0.53333rem solid #00b977;
}
.box-quay .box:after {
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border-left: 0.53333rem solid transparent;
    border-right: 0.53333rem solid #00b977;
}
.box-quay .box .slot-column {
    display: inline-block;
    width: calc((100% - 0.53333rem) / 5);
    height: 100%;
    border-radius: 0.10667rem;
    overflow: hidden;
    background: #333;
    vertical-align: bottom;
    position: relative;
    text-align: center;
}
.box-quay .box .slot-column .slot-transform {
    transform: translateY(-2.73333rem);
}
.box-quay .box .slot-column .slot-transform .slot-num {
    width: 1.46667rem;
    height: 1.46667rem;
    line-height: 1.46667rem;
    background: #e1e1ec;
    border-radius: 50%;
    font-size: 0.8rem;
    color: #0006;
    font-weight: 700;
    margin: 0 auto 0.10667rem;
}
.box-quay .box .slot-column .slot-transform .slot-num:nth-child(3) {
    background: #00e065;
    color: #fff;
}
.box-quay .box .slot-column .slot-transform.slot-scroll {
    -webkit-animation: slotScroll 3s cubic-bezier(0.65, 0.02, 0.65, 1.06);
    animation: slotScroll 3s cubic-bezier(0.65, 0.02, 0.65, 1.06);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.box-quay .box .slot-column .slot-transform.slot-scroll[data-col="1"] {
    -webkit-animation-delay: 0.12s;
    animation-delay: 0.12s;
}

.box-quay .box .slot-column .slot-transform.slot-scroll[data-col="2"] {
    -webkit-animation-delay: 0.24s;
    animation-delay: 0.24s;
}

.box-quay .box .slot-column .slot-transform.slot-scroll[data-col="3"] {
    -webkit-animation-delay: 0.36s;
    animation-delay: 0.36s;
}

.box-quay .box .slot-column .slot-transform.slot-scroll[data-col="4"] {
    -webkit-animation-delay: 0.48s;
    animation-delay: 0.48s;
}
@-webkit-keyframes slotScroll {
    0% {
        -webkit-transform: translateY(-1.44rem);
        transform: translateY(-1.44rem);
    }

    to {
        -webkit-transform: translateY(-42.66667rem);
        transform: translateY(-29.33333rem);
    }
}

@keyframes slotScroll {
    0% {
        -webkit-transform: translateY(-1.44rem);
        transform: translateY(-1.44rem);
    }

    to {
        -webkit-transform: translateY(-42.66667rem);
        transform: translateY(-29.33333rem);
    }
}
.box-quay .box > [class^="num"] {
    width: calc((100% - 0.4rem) / 3);
    height: 2.29333rem;
    background-color: #333;
    border-radius: 0.13333rem;
    position: relative;
    background-position: center;
    background-size: 60%;
    background-repeat: no-repeat;
}
.box-quay .box > [class^="num"]:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    z-index: 2;
    box-shadow: inset 0 -0.13333rem 0.13333rem #0000004d, inset 0 0.13333rem 0.13333rem #0000004d;
}
.box-quay .box > .num1 {
    background-image: url(./img/num1.png);
}
.box-quay .box > .num2 {
    background-image: url(./img/num2.png);
}
.box-quay .box > .num3 {
    background-image: url(./img/num3.png);
}
.box-quay .box > .num4 {
    background-image: url(./img/num4.png);
}
.box-quay .box > .num5 {
    background-image: url(./img/num5.png);
}
.box-quay .box > .num6 {
    background-image: url(./img/num6.png);
}
.text_choose_center {
    background: #fff;
    box-shadow: 0 0.05333rem 0.10667rem #c5c5da40;
    border-radius: 0.26667rem;
    padding: 0.26667rem 0.34667rem;
    text-align: center;
    font-size: 0.34667rem;
    margin-top: 0.33rem;
}
.text_choose_center2 {
    text-align: center;
    font-size: 0.34667rem;
    margin-top: 0.33rem;
}
.text_choose_center3 {
    background: #ffb30099;
    box-shadow: 0 0.05333rem 0.10667rem #c5c5da40;
    border-radius: 0.26667rem;
    padding: 0.3rem 0.3rem 0.5rem;
    text-align: center;
    font-size: 0.34667rem;
    margin-top: 0.33rem;
}
.text_choose_center3.t2a {
    background: #1ac52999;
}
.text_choose_center3.t3a {
    background: #ff6c0099;
}
.text_choose_center3 .state_choose_price > div {
    background: #fff;
    color: #333;
}
.text_choose_center3 .state_choose .chooseItem {
    background: red;
    color: #fff;
}
.text_choose_center .bet_state {
    font-size: 0.3777rem;
    font-weight: bold;
    margin: 0.1rem 0 0.3rem;
    display: inline-block;
    border-bottom: 3px solid red;
}
.state_choose {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
}
.state_choose > div {
    flex: 1;
    margin: 0.1rem;
    border: 1px solid #e5e5e5;
    border-radius: 0.22rem;
    padding: 0.28rem;
    cursor: pointer;
}
.state_choose_price > div {
    width: calc(25% - 0.2rem);
    flex: 0 0 calc(25% - 0.2rem);
    padding: 0.28rem 0;
}
.state_choose2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
}
.state_choose > div > * {
    display: block;
}
.state_choose2 > div {
    background: #fff;
    padding: 0.6rem 0.28rem;
}
.state_choose > div > i {
    font-size: 0.35rem;
    color: red;
    margin-bottom: 0.2rem;
    font-weight: bold;
}
.state_choose .chooseItem {
    background: red;
    border: 1px solid red;
    color: #fff;
}
.state_choose .chooseItem * {
    color: #fff !important;
}
.state_choose .choose_xs {
    width: calc(10% - 0.1rem);
    padding: 0.1rem 0;
    margin: 0.05rem;
    flex: unset;
}
.popup-bet {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    box-shadow: 0 0.05333rem 0.10667rem #c5c5da40;
    width: 100%;
    max-width: 540px;
    z-index: 101;
    padding: 0.3rem;
}
.main:has(.chooseItem) .popup-bet {
    display: block;
}
.main:has(.chooseItem) .footer {
    display: none;
}
.main:has(.tab-game) .footer {
    display: flex !important;
}
.item_choose_footer {
    display: flex;
    justify-content: space-between;
    font-size: 0.35rem;
}
.item_choose_footer .btn-sbmit {
    color: #fff;
    font-size: 0.3rem;
    font-weight: bold;
    text-shadow: 0 0.05333rem 0.02667rem #ff0000;
    border-radius: 1.06667rem;
    border: none;
    background: linear-gradient(180deg, #ee8d8d 0%, #ff0000 100%);
    box-shadow: 0 0.05333rem #e53636;
    padding: 0.1rem 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.item_choose_footer input {
    padding: 0.16rem 0.14667rem;
    margin: 0 0 0 0.2rem;
    font-size: 0.27333rem;
    border: none;
    border-radius: 0.26667rem;
    box-shadow: 0 0.05333rem 0.21333rem #d0d0ed5c;
    border: 1px solid #e5e5e5;
}
.popup-backdrop {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 540px;
    height: 100vh;
    padding: 0.44rem;
    background: #000000c2;
    z-index: 9999;
    display: flex;
    align-items: center;
    flex-flow: row wrap;
}
.popup-main {
    border-radius: 0.44rem;
    overflow: hidden;
    background: #fff;
    width: 100%;
}
.popup-header {
    background: red;
    color: #fff;
    font-size: 0.45rem;
    font-weight: bold;
    padding: 0.32rem;
}
.popup-content {
    padding: 0.32rem;
    font-size: 0.35rem;
    text-align: left;
    line-height: 1.3;
    max-height: 80vh;
    overflow-y: auto;
}
.popup-content div.br {
    margin: 8px 0;
}
.popup-close {
    color: #fff;
    font-size: 0.35rem;
    font-weight: bold;
    text-shadow: 0 0.05333rem 0.02667rem #ff0000;
    border-radius: 0.66667rem;
    border: none;
    background: linear-gradient(180deg, #ee8d8d 0%, #ff0000 100%);
    box-shadow: 0 0.05333rem #e53636;
    padding: 0.15rem 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.32rem auto;
}
.content-history {
    position: relative;
    color: #b7b7b7;
    font-size: 0.3rem;
}
.content-history .item_inner {
    display: flex;
    justify-content: space-between;
    padding: 0.3rem;
    border-bottom: 1px solid #e5e5e5;
}
.content-history .item_history {
    text-align: left;
}
.content-history .id_history_sanh {
    margin: 0.2rem 0 0.08rem;
}
.content-history .sanh {
    color: #565151;
    font-size: 0.36rem;
    font-weight: bold;
}
.content-history .money_history {
    text-align: right;
}
.content-history .money {
    color: #333;
    display: block;
    margin: 0.2rem 0;
    font-size: 0.35rem;
}
.content-history .type_state {
    margin-left: 0.15rem;
    padding: 0.05rem 0.1rem;
    border-radius: 0.1rem;
    color: #fff;
    display: inline-block;
}
.win {
    background-color: #6fe26f;
}
.lose {
    background-color: #b12424;
}
.pending {
    background-color: #ffc200;
}
.swal-button {
    background: red;
}
.swal-footer {
    text-align: center;
}
.swal-modal:has(.swal-icon--success) .swal-button {
    background: #a5dc86 !important;
}
.swal-modal:has(.swal-icon--error) .swal-button {
    background: #f27474 !important;
}
.swal-modal:has(.swal-icon--warning) .swal-button {
    background: #f8bb86 !important;
}
.swal-modal:has(.swal-icon--info) .swal-button {
    background: #3fc3ee !important;
}
.content_profile {
    font-size: 0.4rem;
}
.content_bank {
    display: grid;
    grid-template-columns: 1fr;
    color: #666;
    margin: 0 0 0.5rem;
    font-size: 0.32rem;
}
/*.content_bank:not(:has(.item-banks:nth-child(2))) {
    font-size: 0.4rem;
}
.content_bank:not(:has(.item-banks:nth-child(2))) > div {
    width: 85%;
}*/
.content_bank > div {
    text-align: left;
    border: 1px solid red;
    padding: 0.15rem;
    position: relative;
    max-width: 500px;
    margin: 0 auto;
}
.content_bank .copystk {
    position: absolute;
    right: 5px;
    font-size: 12px;
    color: #fff;
    background: rgba(0, 0, 0, 0.4);
    padding: 3px;
    border-radius: 3px;
    cursor: pointer;
}
/*.content_bank > div:nth-child(even) {
    border-left: 0;
}
.content_bank > div:nth-child(n + 3) {
    border-top: 0;
}*/
.content_bank b {
    color: #000;
}
.huongdan ul li {
    list-style: none;
    margin-bottom: 0.2rem;
    text-align: left;
    position: relative;
    padding-left: 0.4rem;
    font-size: 0.35rem;
    color: #777;
}
.huongdan ul li:before {
    position: absolute;
    content: "";
    width: 0.13333rem;
    height: 0.13333rem;
    background: red;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    left: -0.06667rem;
    top: 0.15333rem;
}
.box-banking {
    background: url(./img/box-banking.png) no-repeat center center;
    background-size: 100% 100%;
    padding: 0.26667rem 0.34667rem;
    text-align: center;
    font-size: 0.34667rem;
    margin-top: 0.33rem;
    margin-left: -0.33rem;
    margin-right: -0.33rem;
    min-height: 5rem;
    position: relative;
}
.box-banking2 {
    background-image: url(./img/box-banking2.png);
    margin-left: 0;
    margin-right: 0;
}
.box-banking .icon_credit {
    position: absolute;
    bottom: 0.9rem;
    left: 0.8rem;
    max-width: 0.9rem;
}
.box-banking .money_banking {
    position: absolute;
    top: 0.9rem;
    left: 0.8rem;
    color: #fff;
    font-size: 0.64rem;
    font-weight: bold;
    text-align: left;
}
.box-banking .money_banking h3 {
    font-weight: normal;
    font-size: 0.4rem;
    color: #f5f5f5;
}
.box-banking .ctk {
    position: absolute;
    bottom: 1.2rem;
    right: 1rem;
    font-size: 0.45rem;
    color: #f2f2f2;
}
.table-result {
    text-align: center;
    font-family: arial;
    width: 100%;
}
.table-result td {
    text-align: center !important;
}
.table-result th {
    border-right: 1px solid #ebebeb;
}
.table-result td,
.table-result th {
    vertical-align: middle;
}
.special-prize {
    float: left;
    width: 100%;
    height: 0.8rem;
    line-height: 0.8rem;
    font-size: 28px;
    font-weight: bold;
    color: #ed1c25;
}
.prize1 {
    float: left;
    width: 100%;
    height: 0.8rem;
    line-height: 0.8rem;
    font-size: 0.48rem;
    font-weight: bold;
}
.prize2 {
    float: left;
    width: 50%;
    height: 0.8rem;
    line-height: 0.8rem;
    font-size: 0.48rem;
    font-weight: bold;
}
.prize3 {
    float: left;
    width: 33.3%;
    height: 0.8rem;
    line-height: 0.8rem;
    font-size: 0.48rem;
    font-weight: bold;
}
.prize4 {
    float: left;
    width: 25%;
    height: 0.8rem;
    line-height: 0.8rem;
    font-size: 0.48rem;
    font-weight: bold;
}
.prize5 {
    float: left;
    width: 33.3%;
    height: 0.8rem;
    line-height: 0.8rem;
    font-size: 0.48rem;
    font-weight: bold;
}
.prize6,
.prize8 {
    float: left;
    width: 33.3%;
    height: 0.8rem;
    line-height: 0.8rem;
    font-size: 0.48rem;
    font-weight: bold;
}
.prize7 {
    float: left;
    width: 25%;
    height: 0.8rem;
    line-height: 0.8rem;
    font-weight: bold;
    font-size: 0.48rem;
}
[class*="prize"]:empty {
    display: none;
}
.bacang {
    margin-bottom: 0.3rem;
    padding-bottom: 0.3rem;
    border-bottom: 1px solid #b7b7b7;
}
.tab-3cang {
    margin-left: -0.2rem;
    margin-right: -0.2rem;
    margin-top: 0.3rem;
}
.tab-3cang > li {
    margin: 0 0.05rem;
    font-size: 0.3rem;
    background: none;
    border: 1px solid #ffc107;
    line-height: 0.222rem;
    padding: 0.1333rem 0;
}
.tab-3cang > li.active {
    background: #ffc107;
}
.tab-3cang > li > div {
    width: 100%;
    height: 33.33%;
}
.tab-game > li {
    margin: 0 0.1rem 0.2rem;
    font-size: 0.28rem;
    min-width: 100px;
}
.box-image {
    box-shadow: 0 0.02rem 0.06rem rgba(0, 0, 0, 0.06);
    background: #fff;
    border-radius: 0.15rem;
    overflow: hidden;
    margin-bottom: 0.35rem;
}
.box-image-title {
    font-size: 0.3rem;
    padding: 0.1rem 0.2rem 0.3rem;
}
.demo {
    position: fixed;
    width: 100%;
    max-width: 540px;
    height: 100vh;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1rem;
    z-index: 99;
    background: #0000009d;
}
.loading {
    position: fixed;
    width: 100%;
    max-width: 540px;
    height: 100vh;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1rem;
    z-index: 999;
    background: #0000009d;
}
body:has(.info_bet) .loading {
    display: none !important;
}
.loader {
    position: relative;
    overflow: hidden;
    display: block;
    height: 50px;
    width: 50px;
    margin: 0;
}
.loader:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 28px;
    height: 28px;
    margin: -16px 0 0 -16px;
    border: 2px solid red;
    border-top-color: #e1e8ed;
    border-right-color: #e1e8ed;
    border-radius: 100%;
    animation: spinner 1s infinite linear;
    transform-origin: center;
}
@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }
}
.route_game input {
    border: 1px solid #e5e5e5;
}
body:has(.admin-theme) .table-result th,
body:has(.admin-theme) .table-result td span,
body:has(.admin-theme) .table-result td {
    font-size: 20px !important;
    margin: 10px 0;
}
body:has(.admin-theme) .table-result td input {
    position: relative;
    top: -10px;
}
.button-admin {
    background: red !important;
    color: #fff !important;
    margin: 5px !important;
}
body:has(.admin-theme) input {
    border: 1px solid #ccc !important;
    border-radius: 5px !important;
    padding: 5px;
    font-size: 16px;
    max-width: 100%;
}
.col-1-1 {
    float: left;
    width: 50%;
    max-width: 250px;
    padding: 5px;
}
.form-admin {
    max-width: 400px;
    margin: 0 auto 15px;
    text-align: left;
}
.form-admin > div > div {
    margin: 15px 0;
    display: flex;
}
.form-admin label {
    width: 180px;
}
.form-admin button {
    margin: auto;
    display: block;
}
body:has(.admin-theme) h2 {
    margin: 15px 0 5px;
}
body:has(.admin-theme) #webpack-dev-server-client-overlay {
    display: none !important;
}
.promotionRule__container-content__rules-item {
    position: relative;
    padding: 0.57333rem 0.24rem 0.33333rem;
    border: 0.01333rem solid #ff6464;
    border-radius: 0.26667rem;
    border-top-right-radius: 0.26667rem;
    background: #fff;
    box-shadow: 0 0.05333rem 0.21333rem #d0d0ed5c;
}
.promotionRule__container-content__rules-item__splitBorder {
    position: absolute;
    width: 100%;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translate(-50%);
    line-height: 0.1;
    text-align: center;
}
.promotionRule__container-content__rules-item__splitBorder > span {
    position: relative;
    top: -0.16rem;
    display: inline-block;
    width: 2.4rem;
    border-top: 0.02667rem solid #fff;
}
.promotionRule__container-content__rules-item__borderTopStyle {
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translate(-50%);
    width: 100%;
}
.promotionRule__container-content__rules-item__borderTopStyle span {
    position: absolute;
    top: 0;
}
.promotionRule__container-content__rules-item__borderTopStyle span:first-of-type {
    left: -0.01333rem;
    width: 0.68rem;
    height: 0.68rem;
    border-top: 0.06667rem solid #ff6464;
    border-left: 0.06667rem solid #ff6464;
    border-top-left-radius: 0.26667rem;
}
.promotionRule__container-content__rules-item__borderTopStyle span:first-of-type:after {
    content: "";
    position: absolute;
    top: 0.29333rem;
    left: 0.2rem;
    width: 0.13333rem;
    height: 0.13333rem;
    border-radius: 50%;
    background-color: #ff6464;
}
.promotionRule__container-content__rules-item__borderTopStyle span:last-of-type {
    right: 0;
    width: 0.68rem;
    height: 0.68rem;
    border-top: 0.06667rem solid #ff6464;
    border-right: 0.06667rem solid #ff6464;
    border-top-right-radius: 0.26667rem;
}
.promotionRule__container-content__rules-item__borderTopStyle span:last-of-type:after {
    content: "";
    position: absolute;
    top: 0.29333rem;
    right: 0.2rem;
    width: 0.13333rem;
    height: 0.13333rem;
    border-radius: 50%;
    background-color: #ff6464;
}
.promotionRule__container-content__rules-item__titleLeft {
    position: absolute;
    top: -0.26667rem;
    left: calc(50% - 1.2rem);
    -webkit-transform: translateX(-50%);
    transform: translate(-50%);
    width: 0.26667rem;
    height: 0.53333rem;
    background-color: #ff6464;
    -webkit-clip-path: polygon(50% 0%, 100% 0%, 50% 50%, 100% 100%, 50% 100%, 0% 50%);
    clip-path: polygon(50% 0%, 100% 0%, 50% 50%, 100% 100%, 50% 100%, 0% 50%);
    z-index: 5;
}
.promotionRule__container-content__rules-item__title {
    position: absolute;
    top: -0.26667rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translate(-50%);
    width: 2.13333rem;
    height: 0.53333rem;
    color: #fff;
    font-size: 0.32rem;
    text-align: center;
    line-height: 0.53333rem;
    background-color: #ff6464;
    -webkit-clip-path: polygon(7% 0%, 93% 0%, 100% 50%, 93% 100%, 7% 100%, 0% 50%);
    clip-path: polygon(7% 0%, 93% 0%, 100% 50%, 93% 100%, 7% 100%, 0% 50%);
}
.promotionRule__container-content__rules-item__titleRight {
    position: absolute;
    top: -0.26667rem;
    left: calc(50% + 1.2rem);
    -webkit-transform: translateX(-50%) rotate(180deg);
    transform: translate(-50%) rotate(180deg);
    width: 0.26667rem;
    height: 0.53333rem;
    background-color: #ff6464;
    -webkit-clip-path: polygon(50% 0%, 100% 0%, 50% 50%, 100% 100%, 50% 100%, 0% 50%);
    clip-path: polygon(50% 0%, 100% 0%, 50% 50%, 100% 100%, 50% 100%, 0% 50%);
    z-index: 5;
}
.promotionRule__container-content__rules-item p {
    color: #666;
    font-size: 0.32rem;
    letter-spacing: 0.01333rem;
    line-height: 0.50667rem;
}
.countdown > div {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.37333rem;
    color: #333;
    font-weight: 400;
}
.countdown > div > div {
    font-size: 0.53333rem;
    height: 0.8rem;
    border-radius: 0.13333rem;
    padding: 0 0.26667rem;
    line-height: 0.8rem;
    background: #efeff4;
    border-radius: 0.08rem;
    margin-left: 0.21333rem;
    color: #00b977;
    text-align: center;
}
.tkq {
    color: rgb(71, 123, 255);
    font-size: 0.4rem;
    font-weight: bold;
    display: block;
    margin-top: 0.2rem;
}
.game-betting {
    margin: 0.8rem auto 0;
}
.game-betting .time-box {
    border-radius: 0.26667rem;
    background: #f94b55 url(./img/bannertimeout.webp) no-repeat 50%;
    background-size: auto 100%;
    display: flex;
    justify-content: space-between;
}
.game-betting .time-box .out {
    height: 100%;
    width: 50%;
    align-items: center;
    justify-content: center;
    border-left: 0.02667rem dashed #fff;
    position: relative;
    padding: 0.26667rem;
}
.game-betting .time-box .out:first-child {
    border: 0;
}
.game-betting .time-box .out:last-child:before {
    content: "";
    height: 0.4rem;
    width: 0.4rem;
    border-radius: 0.4rem;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #f5f5f5;
    transform: translate(-50%, 60%);
}
.game-betting .time-box .out:last-child:after {
    content: "";
    height: 0.4rem;
    width: 0.4rem;
    border-radius: 0.4rem;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #f5f5f5;
    transform: translate(-50%, -60%);
}
.game-betting .time-box .out .txt {
    color: #fff;
    font-size: 0.37333rem;
}
.game-betting .time-box .out .number {
    margin-top: 0.13333rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.game-betting .time-box .out .number .item {
    padding: 0.053333rem;
    background-color: #da2031;
    font-size: 0.53333rem;
    color: #fff;
    font-weight: 600;
}
.game-betting .time-box .out .number .item:first-child {
    padding-left: 0.13333rem;
    background: linear-gradient(135deg, transparent 0.13333rem, #da2031 0) 0 0;
}
.game-betting .time-box .out .number .item:last-child {
    padding-right: 0.13333rem;
    background: linear-gradient(-45deg, transparent 0.13333rem, #da2031 0);
}
.btn-login {
    background: linear-gradient(124.32deg, #ffce1f 12.08%, #ccd26d 85.02%);
    border-radius: 10rem;
    color: #fff;
    font-size: 0.3rem;
    padding: 0.1rem 0.3rem;
    margin-right: 0.3rem;
    cursor: pointer;
}
.btn-register {
    background: linear-gradient(124.32deg, #50a1f2 12.08%, #85daff 85.02%);
    border-radius: 10rem;
    color: #fff;
    font-size: 0.3rem;
    padding: 0.1rem 0.3rem;
    cursor: pointer;
}
.tab-xucsac {
    height: 1.06667rem;
    background: #eeeeee;
    border-radius: 0.13333rem;
    display: flex;
    font-size: 0.29333rem;
    color: #7d7d7d;
    overflow: hidden;
    margin-bottom: 0.3rem;
}
.tab-xucsac > li {
    line-height: 1.06667rem;
    text-align: center;
    flex: 1;
}
.tab-xucsac > li.active {
    background: red;
    color: #fff;
    border-radius: 0.13333rem;
}
.tab-tx {
    height: 1.06667rem;
    background: transparent;
    border-radius: 0.13333rem;
    display: flex;
    font-size: 0.29333rem;
    color: #7d7d7d;
    overflow: hidden;
    margin-bottom: 0.3rem;
}
.tab-tx > li {
    background: #ffb300;
    color: #f3f3f3;
    line-height: 1.06667rem;
    text-align: center;
    flex: 1;
}
.tab-tx > li:nth-child(2) {
    background: #1ac529;
}
.tab-tx > li:nth-child(3) {
    background: #ff6c00;
}
.tab-tx > li.active {
    font-size: 0.35rem;
    color: #fff;
    font-weight: bold;
}
.state_xucsac > div:first-child {
    width: 100%;
    flex-basis: 100%;
}
.history_xucsac > div {
    width: 0.53333rem;
    height: 0.53333rem;
    background-repeat: no-repeat;
    background-size: 0.53333rem;
    background-position: center;
    margin: 0 0.05rem;
}
.history_xucsac > .n1 {
    background-image: url(./img/n1.png);
}
.history_xucsac > .n2 {
    background-image: url(./img/n2.png);
}
.history_xucsac > .n3 {
    background-image: url(./img/n3.png);
}
.history_xucsac > .n4 {
    background-image: url(./img/n4.png);
}
.history_xucsac > .n5 {
    background-image: url(./img/n5.png);
}
.history_xucsac > .n6 {
    background-image: url(./img/n6.png);
}
.main .box-game.op.xsmb > a {
    background: url(./img/mien-bac.png);
    background-size: cover;
}
.main .box-game.op.xsmt > a {
    background: url(./img/mien-trung.png);
    background-size: cover;
}
.main .box-game.op.xsmn > a {
    background: url(./img/mien-nam.png);
    background-size: cover;
}
.main .box-game.op.xsmb a > *,
.main .box-game.op.xsmt a > *,
.main .box-game.op.xsmn a > * {
    display: none;
}
.contentHtml {
    max-height: 300px;
    overflow-y: auto;
}
.btn-marquee {
    background: red;
    color: #fff;
    border: 0;
    border-radius: 0;
    padding: 5px 10px;
    cursor: pointer;
}
.marquees {
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: left;
    color: #555;
    background: #fff;
    padding: 0;
    margin-top: -10px;
    overflow: hidden;
    line-height: 2;
}
.marquees > div {
    line-height: 1;
    flex: 1;
}
.marquees > div:first-child {
    flex: 0 0 30px;
    text-align: left;
    color: #ff0000;
    background: #fff;
    position: relative;
    z-index: 1;
    padding: 0 10px;
}
.marquee {
    width: calc(100% - 140px);
    overflow: hidden;
    white-space: nowrap;
    line-height: 1;
}

.marquee > p {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    animation: marquee 10s linear infinite;
    line-height: 1.3;
}

@keyframes marquee {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}
.chaychu {
    white-space: nowrap;
    overflow: hidden;
    line-height: 2;
}
@keyframes chuchay {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}
.quill {
    width: 100%;
    margin-bottom: 40px;
    min-height: 200px;
}
.logologin {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 50px;
    max-width: 300px;
    max-height: 150px;
    width: auto;
}
@media (max-width: 1600px) {
    table {
        width: 100% !important;
    }
}
.lsgd-table {
    display: flex;
    font-size: 0.32rem;
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
    margin-bottom: 5px;
}
.lsgd-table > div {
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%;
    text-align: left;
}
.table-lsgd td {
    padding: 0 0.1rem;
}
.table-result td span:empty {
    display: none;
}
.table-result td {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
}
.MuiBox-root form + table {
    margin-top: 20px;
}
[class*="btn-admin"] {
    border: 0;
    border-radius: 5px;
    color: #fff;
    background: red;
    box-shadow: none;
    padding: 7px 12px;
    margin-left: 3px;
}
.btn-submit.btn-admin-1 {
    background: #54bd33;
    padding: 7px 12px;
}
.btn-submit.btn-admin-2 {
    background: #9d8ae7;
    padding: 7px 12px;
}
.MuiTable-root {
    width: 100% !important;
}
@media (min-width: 1200px) {
    .MuiTable-root {
        min-width: 1200px;
    }
}
.MuiTable-root th:last-child {
    text-align: center;
    justify-content: center;
}
.MuiTable-root td:last-child {
    text-align: center;
    justify-content: center;
}
.result_admin_choose table,
.max900 {
    max-width: 900px;
}
.current_bet {
    border: 0.5px solid #eee;
    margin-bottom: 20px;
}
.current_bet > div {
    display: flex;
}
.current_bet > div > div {
    padding: 10px;
    flex: 1;
    border: 0.5px solid #eee;
}
.current_bet > div:first-child {
    font-weight: bold;
}
.table_ls_tx th {
    font-size: 0.4rem;
    padding: 0.1rem;
}
.table_ls_tx td {
    font-size: 0.3rem;
    padding: 0.1rem;
    vertical-align: middle;
}
.table_ls_tx .history_xucsac > div {
    width: 0.44rem;
    height: 0.44rem;
    background-size: 0.44rem;
}
.t-blue {
    color: #4c49e3;
}
.t-green {
    color: #32c977;
}
.banglaisuat {
    margin: 30px 0 10px;
}
.banglaisuat tbody {
    border-left: 1px solid #ebebeb;
    border-right: 1px solid #ebebeb;
    background: #fff;
}
.banglaisuat td {
    padding: 5px 10px;
    font-size: 15px;
    text-align: center !important;
}
.btn-1 {
    background: #ffd3d3;
    color: #d32f2f;
    border: 0;
    border-radius: 5px;
    width: 120px;
    padding: 10px;
    font-size: 0.333333rem;
    margin-bottom: 0.3rem;
}
.btn-2 {
    background: #d32f2f;
    color: #fff;
    border: 0;
    border-radius: 5px;
    width: 120px;
    padding: 10px;
    font-size: 0.333333rem;
    margin-bottom: 0.3rem;
}
.tongloinhuan {
    position: relative;
    font-size: 0.4rem;
    top: -20px;
    margin-bottom: -10px;
    color: #fbff59;
}
.bangthongke td {
    font-size: 1rem;
    padding: 10px;
    text-align: left;
}
.avb_ {
    margin-bottom: 30px;
}
@media (min-width: 1200px) {
    .avb_ {
        column-count: 2;
    }
}
.invite {
    margin-top: 20px;
    margin-left: -0.32rem;
    margin-right: -0.32rem;
    position: relative;
    background: url(./img/invite.png) left top / cover no-repeat;
    height: 180px;
    text-shadow: rgb(255, 255, 136) 1px 0px 10px;
    color: rgba(255, 255, 255, 0.8);
    padding: 0 0.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.text-invite {
    width: 50%;
    font-size: 0.4rem;
}
.text-invite h3 {
    font-size: 0.3rem;
    margin: 0 0 0.3rem;
    text-align: left;
}
.text-invite h4 {
    font-size: 0.5rem;
    margin-top: 0;
    font-weight: bold;
}
.copy-invite {
    color: #fff;
    background: #30c1ae;
    display: inline-block;
    margin-left: 8px;
    font-size: 11px;
    border-radius: 5px;
    padding: 2px 5px;
}
.taikhoandautu {
    position: relative;
    background: url(./img/taikhoandautu.png) left top / cover no-repeat;
    height: 135px;
    color: #fff;
    padding: 0.2rem 0.35rem;
    margin-top: 20px;
    font-size: 0.4rem;
    text-align: left;
    border-radius: 0.25rem;
}
.tienlai {
    background: url(./img/tienlai.png) left top / cover no-repeat;
}
.taikhoandautu > div {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    height: 100%;
}
.taikhoandautu > div > * {
    width: 100%;
    height: 50%;
}
.taikhoandautu h3 {
    font-size: 0.5rem;
    font-weight: bold;
    margin: 0;
    height: 0.5rem;
}
.baocaodaily {
    margin: 0.5rem 0;
}
.baocaodaily h3 {
    font-size: 0.5rem;
    font-weight: bold;
    margin: 0.5rem 0 0.3rem;
    height: 0.5rem;
}
.baocaodaily table thead {
    background: none;
}
.baocaodaily table thead th {
    background: #fff;
    font-size: 0.3rem;
    color: #333;
}
.baocaodaily table tbody td {
    font-size: 0.25rem;
    text-align: center;
}
.baocaodaily table tbody td a {
    color: #168be1;
    font-weight: 500;
    font-size: 0.28rem;
}
.badge {
    padding: 5px 8px;
    font-size: 13px;
    color: #fff;
    background: #ff0000;
    border-radius: 15px;
    cursor: pointer;
    white-space: nowrap;
    border: 0;
}
.badge.green {
    background: #30c135;
}
.badge.large {
    font-size: 15px;
    padding: 10px 15px;
}
.table-admin td {
    padding: 0.3rem;
    font-size: 13px;
    line-height: 1.3;
    border-top: 1px solid #dee2e6;
    border-bottom: 0;
}
.table-admin th {
    font-weight: bold !important;
    color: #234983 !important;
    padding: 10px 5px !important;
}
.chuyendoihoahong {
    color: #fff;
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    padding: 8px 20px;
    align-items: center;
    margin: 0.32rem 0 0 !important;
}
.chuyendoihoahong h3 {
    color: #ffff02;
    font-size: 20px;
    margin-top: 10px;
    font-weight: bold;
}
.thuongtop {
    padding: 0 0.32rem 0.32rem;
}
.thuongtop .item {
    margin-bottom: 0.32rem;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 0.02rem 0.06rem rgba(0, 0, 0, 0.2), inset 0 0.01rem 0.02rem rgba(255, 250, 241, 0.8);
    padding: 0.32rem;
    border-radius: 5px;
    color: #000;
    font-weight: 600;
    font-size: 0.32rem;
}
.thuongtop .item .name {
    text-align: left;
}
.thuongtop .item ins {
    display: block;
    text-decoration: none;
    color: #f03535;
}
.thuongtop .icon-rank {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0.88rem;
    height: 0.88rem;
    background-size: 100% 100%;
    background-position: 50%;
    background-image: url(./img/no4.svg);
    font-family: Play;
    font-style: normal;
    font-weight: 700;
    font-size: 0.51913rem;
    line-height: 0.16rem;
    color: #fff;
    text-shadow: 0 0.01565rem 0.0313rem rgba(105, 185, 92, 0.6);
    margin-left: auto;
}
.thuongtop .icon-rank.icon-rank_1 {
    background-image: url(./img/no1.svg);
    text-shadow: 0 0.01565rem 0.0313rem rgba(241, 128, 45, 0.6);
}
.thuongtop .icon-rank.icon-rank_2 {
    background-image: url(./img/no2.svg);
    text-shadow: 0 0.01565rem 0.0313rem rgba(156, 165, 188, 0.6);
}
.thuongtop .icon-rank.icon-rank_3 {
    background-image: url(./img/no3.svg);
    text-shadow: 0 0.01565rem 0.0313rem rgba(199, 120, 102, 0.6);
}
.thuongtop .item .prize span {
    color: #fbcf38;
    font-weight: 600;
    font-size: 0.3rem;
}
.thuongtop .item .prize {
    font-weight: normal;
    color: #555;
    font-size: 0.25rem;
    width: 30%;
    padding-top: 0.1rem;
}
.thuongtop .rank {
    width: 30%;
}
.thuongtop .name {
    width: 40%;
}
.sub_tab {
    display: flex;
    justify-content: space-around;
    margin-bottom: 0.32rem;
}
.sub_tab li {
    min-width: 100px;
    padding: 5px;
    border-bottom: 3px solid #ebebeb;
    cursor: pointer;
}
.sub_tab li.active {
    border-color: red;
}
.no_record {
    font-size: 14px;
    text-align: center;
    color: #666;
    position: relative;
}
.bounce {
    -webkit-animation: bounce 1.5s ease-in infinite alternate;
    animation: bounce 1.5s ease-in infinite alternate;
}
@-webkit-keyframes bounce {
    0%,
    100%,
    20%,
    50%,
    80% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
    60% {
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
    }
}
@keyframes bounce {
    0%,
    100%,
    20%,
    50%,
    80% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
    60% {
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
    }
}
.gameapi-list {
    display: flex;
    flex-flow: row wrap;
}
.gameapi-item {
    width: calc(100% / 3);
    padding: 10px;  
    position: relative;
}
.gameapi-item img {
    float: left;
    width: 100%;
    box-shadow: 0 0.02rem 0.06rem rgba(0,0,0,.06);
    background: #fff;
    border-radius: 10px
}
.gameapi-name {
    background: linear-gradient(180deg,rgba(232,35,35,0.9),rgba(201,13,13,0.9));
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 10px;
    width: calc(100% - 20px);
    font-size: 13px;
    text-align: center;
    padding: 10px 2px;
    border-radius: 0 0 10px 10px;
}
.view-more {
    font-size: 16px;
    color: #ff0000;
    border: 2px solid #ff0000;
    border-radius: 50px;
    padding: 5px 15px;
    width: 200px;
    margin: 0 auto;
    text-transform: uppercase;
    font-weight: bold;
}
.relative-input {
    position: relative;
}
.absolute-input {
    position: absolute;
    top: 0.1rem;
    right: calc(5% + 0.1rem);
    border-radius: 0.26667rem;
    background: #fce406;
    height: 0.97333rem;
    line-height: 0.97333rem;
    padding:0 0.3rem;
    cursor: pointer;
}